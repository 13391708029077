import {verifiedFetch} from '@github-ui/verified-fetch'
import {useEffect, useRef, useState} from 'react'

export interface CopilotSummarizeBannerLoaderProps {
  /*
   * A relative URL like `/:user_id/:repository/issues/:id/copilot-summaries-banner` to asynchronously load the
   * Copilot summary banner for the content being shown on the page.
   */
  bannerPath: string
  /*
   * Is the feature enabled? If not, the banner will not be loaded.
   */
  featureEnabled?: boolean
}

export function CopilotSummarizeBannerLoader({bannerPath, featureEnabled}: CopilotSummarizeBannerLoaderProps) {
  const [bannerHtml, setBannerHtml] = useState<string | null>(null)
  const shouldLoadBanner = bannerPath.length > 0 && featureEnabled
  const isLoadingRef = useRef(false)

  const hasBannerHtml = bannerHtml !== null

  useEffect(() => {
    if (hasBannerHtml) return // Banner has already been loaded
    if (!shouldLoadBanner) return
    if (isLoadingRef.current) return // Don't start loading the banner again if we're already loading it
    let current = true

    const loadBanner = async () => {
      isLoadingRef.current = true
      const response = await verifiedFetch(bannerPath, {method: 'GET', headers: {Accept: 'text/html'}})
      const newBannerHtml = await response.text()
      if (current) setBannerHtml(newBannerHtml)
    }

    void loadBanner()

    return () => {
      if (current) isLoadingRef.current = false
      current = false
    }
  }, [hasBannerHtml, bannerPath, shouldLoadBanner])

  if (shouldLoadBanner && bannerHtml) {
    // The banner is HTML we wrote, it's not user-generated.
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{__html: bannerHtml}} />
  }

  return null
}

try{ CopilotSummarizeBannerLoader.displayName ||= 'CopilotSummarizeBannerLoader' } catch {}