import type {IssuePickerItem} from '@github-ui/item-picker/IssuePicker'
import {useIssueFiltering} from '@github-ui/item-picker/useIssueFiltering'
import {IssueOpenedIcon} from '@primer/octicons-react'
import {ActionList, TextInput} from '@primer/react'
import {Octicon} from '@primer/react/deprecated'
import {Dialog} from '@primer/react/experimental'
import {useCallback, useRef, useState} from 'react'

export type IssueDialogPickerProps = {
  repository: string
  owner: string
  onClose: () => void
  onIssueSelected: (issue: IssuePickerItem) => void
  hiddenIssueIds?: string[]
}

export function IssueDialogPicker({
  repository,
  owner,
  onClose,
  onIssueSelected,
  hiddenIssueIds,
}: IssueDialogPickerProps): JSX.Element | undefined {
  const returnFocusRef = useRef(null)
  const [filter, setFilter] = useState('')
  const {isLoading, isError, items} = useIssueFiltering(filter, owner, `${owner}/${repository}`, hiddenIssueIds)

  const onIssueSelection = useCallback(
    (selectedIssues: IssuePickerItem[]) => {
      if (selectedIssues && selectedIssues.length > 0 && selectedIssues[0]) {
        onIssueSelected(selectedIssues[0])
      }
    },
    [onIssueSelected],
  )

  const hasResults = items && items.size > 0

  return (
    <Dialog
      returnFocusRef={returnFocusRef}
      title={`${owner}/${repository}`}
      subtitle={'Select which issue this is duplicate of'}
      onClose={onClose}
      aria-labelledby="header"
    >
      <TextInput placeholder="Filter or paste URL" onChange={e => setFilter(e.target.value)} />
      {isLoading && !isError && <>Loading...</>}
      {!isLoading && isError && <>Error loading issues</>}
      {!isLoading && !isError && !hasResults && <>No issues found</>}
      {!isLoading && hasResults && (
        <ActionList>
          {[...items.values()].map(issue => (
            <ActionList.Item key={issue.id} onSelect={() => onIssueSelection([issue])}>
              <ActionList.LeadingVisual>
                <Octicon icon={IssueOpenedIcon} sx={{path: {fill: 'open.fg'}}} />
              </ActionList.LeadingVisual>
              {issue.title}
              <ActionList.Description variant="block">{`#${issue.number}`}</ActionList.Description>
            </ActionList.Item>
          ))}
        </ActionList>
      )}
    </Dialog>
  )
}

try{ IssueDialogPicker.displayName ||= 'IssueDialogPicker' } catch {}