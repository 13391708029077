export const LABELS = {
  actionListCompletedDescription: 'Done, closed, fixed, resolved',
  actionListNotPlannedDescription: "Won't fix, can't repro, duplicate, stale",
  actionListDuplicateDescription: 'Duplicate of another issue',
  closeIssue: 'Close issue',
  closeIssueWithComment: 'Close with comment',
  closeAsCompleted: 'Close as completed',
  closeAsNotPlanned: 'Close as not planned',
  closeAsDuplicate: 'Close as duplicate',
  moreOptions: 'More options',
  reOpenIssue: 'Reopen Issue',
  updateIssueRoleDescription: 'update issue state options menu',
}
