import {Button} from '@primer/react'
import {Suspense, useCallback, useMemo, useState} from 'react'

import {CreateIssueDialogEntryInternal, type CreateIssueDialogEntryProps} from './dialog/CreateIssueDialogEntry'
import {CreateIssueButtonLoading} from './CreateIssueButtonLoading'
import {ssrSafeWindow} from '@github-ui/ssr-utils'
import {relativeIssueNewPathFromExisting, loginPath} from './utils/urls'
import {GlobalCommands} from '@github-ui/ui-commands'
import {useNavigate} from '@github-ui/use-navigate'
import {isLoggedIn} from '@github-ui/client-env'

export type CreateIssueButtonProps = {
  label: string
  size?: 'small' | 'medium'
  isDialogOpenByDefault?: boolean
} & Omit<CreateIssueDialogEntryProps, 'setIsCreateDialogOpen' | 'isCreateDialogOpen'>

export const CreateIssueButton = ({label, size = 'medium', ...props}: CreateIssueButtonProps): JSX.Element | null => {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const navigate = useNavigate()
  const isViewerLoggedIn = isLoggedIn()

  const openDialog = useCallback(() => {
    if (isViewerLoggedIn) {
      setIsCreateDialogOpen(true)
    } else {
      navigate(loginPath())
    }
  }, [isViewerLoggedIn, navigate])

  const onCreateIssueShortcutClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      openDialog()
    },
    [openDialog],
  )

  const renderedButton = useMemo(() => {
    return (
      <>
        {isViewerLoggedIn && <GlobalCommands commands={{'issue-create:new': openDialog}} />}
        <RenderedCreateButton size={size} label={label} onClick={onCreateIssueShortcutClick} />
      </>
    )
  }, [openDialog, size, label, isViewerLoggedIn, onCreateIssueShortcutClick])

  if (!isCreateDialogOpen) return renderedButton

  return (
    <Suspense fallback={<CreateIssueButtonLoading label={label} size={size} />}>
      {renderedButton}

      <CreateIssueDialogEntryInternal
        isCreateDialogOpen={isCreateDialogOpen}
        setIsCreateDialogOpen={setIsCreateDialogOpen}
        {...props}
      />
    </Suspense>
  )
}

type RenderedCreateButtonProps = {
  label: string
  size?: 'small' | 'medium'
  onClick: (e: React.MouseEvent) => void
}

const RenderedCreateButton = ({size, label, onClick}: RenderedCreateButtonProps) => {
  const pathname = ssrSafeWindow?.location?.pathname ?? ''
  const baseUrlForFullscreenLink = useMemo(() => {
    if (isLoggedIn()) {
      return relativeIssueNewPathFromExisting(pathname)
    } else {
      return loginPath()
    }
  }, [pathname])

  // We want to default to the underlying anchor functionality when the user is holding down the cmd or ctrl key
  // and therefore ignore the custom onClick functionality.
  const ignoreOnClickIfCmdOrCtrlPressed = (e: React.MouseEvent, clickHandler: (e: React.MouseEvent) => void) => {
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    if (!e.ctrlKey && !e.metaKey) {
      clickHandler(e)
    }

    // ..bubble down to the underlying anchor functionality
  }

  return (
    <Button
      size={size}
      variant={'primary'}
      onClick={(e: React.MouseEvent) => ignoreOnClickIfCmdOrCtrlPressed(e, onClick)}
      as="a"
      href={baseUrlForFullscreenLink}
      target="_blank"
    >
      {label}
    </Button>
  )
}

try{ CreateIssueButton.displayName ||= 'CreateIssueButton' } catch {}
try{ RenderedCreateButton.displayName ||= 'RenderedCreateButton' } catch {}