/**
 * @generated SignedSource<<7291ea9424786272844118d5cbf82676>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderViewer$data = {
  readonly number: number;
  readonly titleHTML: string;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderMenu">;
  readonly " $fragmentType": "HeaderViewer";
};
export type HeaderViewer$key = {
  readonly " $data"?: HeaderViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderViewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderViewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleHTML",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderMenu"
    }
  ],
  "type": "Issue",
  "abstractKey": null
};

(node as any).hash = "f376e408e6c4293ea7cd7a24b98b1c91";

export default node;
