import {Box, Button, Text} from '@primer/react'
import {Section} from '@github-ui/issue-metadata/Section'
import {ReadonlySectionHeader} from '@github-ui/issue-metadata/ReadonlySectionHeader'
import {useAlive} from '@github-ui/use-alive'
import {BellIcon, BellSlashIcon} from '@primer/octicons-react'
import {useCallback, useEffect, useState, useRef, useTransition} from 'react'
import {commitUpdateIssueSubscriptionMutation} from '../../mutations/update-issue-subscription'
import {graphql, useFragment, useRefetchableFragment, useRelayEnvironment} from 'react-relay'
import {LABELS} from '../../constants/labels'
import type {SubscriptionSectionFragment$key} from './__generated__/SubscriptionSectionFragment.graphql'
import type {SubscriptionSectionRefetchableFragment$key} from './__generated__/SubscriptionSectionRefetchableFragment.graphql'
import {ALIVE_REASONS} from '../../constants/alive'

export type SubscriptionSectionProps = {
  issue: SubscriptionSectionFragment$key | SubscriptionSectionRefetchableFragment$key
}

export function SubscriptionSection({issue}: SubscriptionSectionProps) {
  const {threadSubscriptionChannel, id} = useFragment(
    graphql`
      fragment SubscriptionSectionFragment on Issue {
        id
        threadSubscriptionChannel
      }
    `,
    issue as SubscriptionSectionFragment$key,
  )

  // threadSubscriptionChannel should not be null for logged-in users, but check to ensure that it is loaded
  // because useAlive expects a valid channel name.
  return threadSubscriptionChannel ? (
    <SubscriptionSectionInternal
      issue={issue as SubscriptionSectionRefetchableFragment$key}
      id={id}
      threadSubscriptionChannel={threadSubscriptionChannel}
    />
  ) : null
}

export function SubscriptionSectionInternal({
  id,
  issue,
  threadSubscriptionChannel,
}: {
  id: string
  issue: SubscriptionSectionRefetchableFragment$key
  threadSubscriptionChannel: string
}) {
  const [subscriptionAnnouncement, setSubscriptionAnnouncement] = useState<string>()
  const shouldUpdateAnnouncement = useRef(false)
  const environment = useRelayEnvironment()
  const [, startTransition] = useTransition()

  const updateSubscriptionAnnouncement = useCallback((newSubscribed: boolean, success = true) => {
    if (success) {
      // If it was successful, announce the change to the user
      setSubscriptionAnnouncement(
        newSubscribed ? LABELS.notifications.subscribedAnnouncement : LABELS.notifications.unsubscribedAnnouncement,
      )
    } else {
      setSubscriptionAnnouncement(LABELS.somethingWentWrong)
    }
  }, [])

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment SubscriptionSectionRefetchableFragment on Issue
      @refetchable(queryName: "SubscriptionSectionRefetchableFragmentQuery") {
        viewerThreadSubscriptionFormAction
      }
    `,
    issue,
  )
  const {viewerThreadSubscriptionFormAction} = data
  const subscribed = viewerThreadSubscriptionFormAction === 'UNSUBSCRIBE'

  const refreshSubscription = useCallback(() => {
    const query = () => {
      refetch(
        {},
        {
          fetchPolicy: 'network-only',
          onComplete: () => {
            shouldUpdateAnnouncement.current = true
          },
        },
      )
    }
    startTransition(() => query())
  }, [refetch, startTransition])

  useEffect(() => {
    if (shouldUpdateAnnouncement.current) {
      updateSubscriptionAnnouncement(subscribed)
      shouldUpdateAnnouncement.current = false
    }
  }, [subscribed, updateSubscriptionAnnouncement])

  const toggleSubscription = useCallback(() => {
    const newSubscribed = !subscribed
    commitUpdateIssueSubscriptionMutation({
      environment,
      input: {
        subscribableId: id,
        state: newSubscribed ? 'SUBSCRIBED' : 'UNSUBSCRIBED',
      },
      onCompleted: () => {
        updateSubscriptionAnnouncement(newSubscribed)
      },
      onError: () => updateSubscriptionAnnouncement(subscribed, false),
    })
  }, [environment, id, subscribed, updateSubscriptionAnnouncement])

  // Thread subscription updates are scoped to an individual user and emitted via signed user channels.
  useAlive(threadSubscriptionChannel, (event: {reason: string}) => {
    if (!event?.reason) return
    // Refetch subscription information if we receive a message that the subscription has changed.
    // We cannot rely on the "reason" to determine the new state, because we still receive a subscription
    // message if the thread is "ignored".
    if (event.reason === ALIVE_REASONS.SUBSCRIBED && !subscribed) {
      refreshSubscription()
    } else if (event.reason === ALIVE_REASONS.UNSUBSCRIBED && subscribed) {
      refreshSubscription()
    }
  })

  return (
    <Section sectionHeader={<ReadonlySectionHeader title="Notifications" />}>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', px: 2, pt: 2, alignItems: 'stretch'}}>
        <Button
          aria-describedby="issue-viewer-subscription-description"
          leadingVisual={subscribed ? BellSlashIcon : BellIcon}
          onClick={toggleSubscription}
        >
          {subscribed ? LABELS.notifications.subscribedButton : LABELS.notifications.unsubscribedButton}
        </Button>
        <Text id="issue-viewer-subscription-description" sx={{fontSize: 0, mb: 2, mt: 2, color: 'fg.muted'}}>
          {subscribed ? LABELS.notifications.subscribedDescription : LABELS.notifications.unsubscribedDescription}
        </Text>
        <span className="sr-only" aria-live="polite">
          {subscriptionAnnouncement}
        </span>
      </Box>
    </Section>
  )
}

export function SubscriptionSectionFallback() {
  return (
    <Section sectionHeader={<ReadonlySectionHeader title="Notifications" />}>
      <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', px: 2, pt: 2, alignItems: 'stretch'}}>
        <Button aria-describedby="issue-viewer-subscription-description" leadingVisual={BellIcon}>
          {LABELS.notifications.unsubscribedButton}
        </Button>
        <Text id="issue-viewer-subscription-description" sx={{fontSize: 0, mb: 2, mt: 2, color: 'fg.muted'}}>
          {LABELS.notifications.unsubscribedDescription}
        </Text>
      </Box>
    </Section>
  )
}

try{ SubscriptionSection.displayName ||= 'SubscriptionSection' } catch {}
try{ SubscriptionSectionInternal.displayName ||= 'SubscriptionSectionInternal' } catch {}
try{ SubscriptionSectionFallback.displayName ||= 'SubscriptionSectionFallback' } catch {}