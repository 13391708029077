/**
 * @generated SignedSource<<3ed10d4078f9c697a586f7f6ef0f2042>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewIssueTimelineIssueFragment$data = {
  readonly id: string;
  readonly url: string;
  readonly " $fragmentSpreads": FragmentRefs<"NewIssueTimelineBackFragment" | "NewIssueTimelineFrontFragment">;
  readonly " $fragmentType": "NewIssueTimelineIssueFragment";
};
export type NewIssueTimelineIssueFragment$key = {
  readonly " $data"?: NewIssueTimelineIssueFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewIssueTimelineIssueFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "count",
    "value": 15
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewIssueTimelineIssueFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "NewIssueTimelineFrontFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "NewIssueTimelineBackFragment"
    }
  ],
  "type": "Issue",
  "abstractKey": null
};
})();

(node as any).hash = "d97428331fe39c36e94c59946cb0934f";

export default node;
